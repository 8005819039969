<template>
    <a-col :span="24" :lg="24" style="padding-bottom: 20px;padding-top: 20px;">
        <div  v-if="left == '1'">
            <a-avatar
                src="images/staff_1024.png"
                alt="Han Solo"
                style="width: 50px;height: 50px;float: left;"
            />
            <a-col :span="24" :lg="12">
                <div style="font-size: 12px;font-weight: bold;"> [ ID : {{ room_id }} ] {{time}} </div>
                <div class="message" v-html="new_content">
                </div>
                <a-icon v-if="load" type="loading" style="margin-top: 10px;"/>
            </a-col>
        </div>
        <div  v-if="left == '0'">
            <a-avatar
                src="images/face-6.jpeg"
                alt="Han Solo"
                style="width: 50px;height: 50px;float: right;"
            />
            <a-col :span="24" :lg="12" class="c-r" >
                <div style="font-size: 12px;text-align: right;font-weight: bold;">[ ID : {{ room_id }} ] {{time}}</div>
                <div class="message c-r" v-html="new_content">
                </div>
            </a-col>
        </div>
    </a-col>
</template>
<style>
.c-r{
	float: right;
}
.c-l{
	float: left;
}
.av-img,.av-con{
	display: inline-block;
}
.ant-avatar{
	width: 50px;
    height: 50px;
}
.message{
	font-size: 15px;
    background-color: #e5e7eb;
    border-radius: 5px;
    min-width: 50px;
    min-height: 30px;
    line-height: 25px;
}
.message p{
    margin: 5px 10px 5px 10px;
    display: inline-block
}
</style>
<script>
    import {marked} from 'marked';
    import hljs from 'highlight.js'
    import 'highlight.js/styles/foundation.css'
    const render = new marked.Renderer()
        marked.setOptions({
        renderer: render, // 这是必填项
        gfm: true, // 启动类似于Github样式的Markdown语法
        pedantic: false, // 只解析符合Markdwon定义的，不修正Markdown的错误
        sanitize: false, // 原始输出，忽略HTML标签（关闭后，可直接渲染HTML标签）
        // 高亮的语法规范
        highlight: (code, lang) => hljs.highlight(code, {language: lang}).value,
    })
    export default({
		props: {
			room_id: {
				type: Number,
				default: 0,
			},
			left: {
				type: String,
				default: '0',
			},
			content: {
				type: String,
				default: '你好',
			},
            time:{
				type: String,
				default: '',
            
            },
            load:{
				type: Boolean,
				default: false,
            
            }
		},
        computed: {
            new_content(){
                return this.safeMarked(this.content)
            }
        },
        methods:{
            safeMarked(text) {
                try {
                    return marked(text)
                } catch (err) {
                    return text
                }
            }
        }
    })
</script>
