<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div class="h-full">
		<!-- Table & Timeline -->
		<a-row :gutter="24" class="h-full">
			<!-- Table -->
			<a-col :span="24" :lg="24" class="h-full">
				<a-card :bordered="false"  class="header-solid h-full" :bodyStyle="{height:'100%',padding: 0}">
					<div class="top-1" ref="mianscroll"  v-if="room_id">
						<messageComponents v-for="item in messages" :room_id="room_id" :key="item.id" :content="item.message" :time="item.time" :load="item.load" :left="item.type"></messageComponents>
					</div>
					<div class="bottom-1"  v-if="room_id">
						<a-col :span="24" :lg="18">
							<a-textarea placeholder="来说点什么吧...(Shift + Enter = 换行)(超长文本可以使用右侧编辑按钮编辑)" @keypress="handleKeyCode" v-model="message_value" :disabled="send_disabled" class="mess-inp"/>
						</a-col>
						<a-col :span="24" :lg="6">
							<a-modal
								title="编辑问题消息"
								:visible="visible"
								:footer="null"
								@cancel="cancelModel"
								>
								<a-textarea placeholder="来说点什么吧...(Shift + Enter = 换行)" v-model="message_value" :autoSize="{ minRows: 15}"/>
							</a-modal>
							<a-button type="primary" @click="showModal" shape="circle" size="small" icon="edit" :disabled="send_disabled"/>
							<a-button type="primary" class="mess-bu" icon="export" @click="sendMessage" :disabled="send_disabled">发送</a-button>
						</a-col>
					</div>
				</a-card>
			</a-col>
			<!-- / Table -->
		</a-row>
		<!-- / Table & Timeline -->
	</div>
</template>
<style scoped>
.mess-bu{
	margin-left: 10px;
	margin-top: 10px;
}
.mess-inp{
	margin-left: 10px;
	resize: none;
}
.top-1{
	height: 85%;
	width: 100%;
	overflow: hidden;
	position: absolute;
	overflow-y: auto;
}
.bottom-1{
	bottom: 10px;position: absolute;width: 90%;
}
</style>
<script>
	import axios from 'axios'
	import messageComponents from "../components/Message/UserMesage.vue";
	export default ({
		components: {
			messageComponents
		},
		props: {
			resetUseCount: {
				type: Function,
				default: null
			}
		},
		data() {
			return {
				visible:false,
				room_id:0,
				room_type:0,
				message_value:'',
				send_disabled:false,
				messages:[
				]
			}
		},
		methods: {
			showModal(){
				this.visible = true
			},
			cancelModel(){
				this.visible = false
			},
			handleKeyCode(event){
				console.log(event)
				if (event.keyCode == 13) {
					if (event.shiftKey) {
						this.message_value = this.message_value;
						console.log(this.message_value)
					}else{
						this.sendMessage()
					}
				}
			},
			async chatStream(message_value,room_id,id,question_time,room_type) {
				let user_info = window.localStorage.getItem('user_info')
				let user_info_json = JSON.parse(user_info)
				try {
					var url = 'http://ai.tool.winndoo.cn/api/messages'
					console.log(this.$route.query.room_type)
					if(this.$route.query.room_type == 3){
						url = 'http://43.130.68.13/api/messages'
					}
					const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify({
						id: id,
						question: message_value,
						user_id: user_info_json.user_id,
						room_id: room_id,
						question_time: question_time,
					}),
					})
					console.log(response)
					console.log(response.status)
					const reader = response.body.getReader()
					while (true) {
						const {done, value} = await reader.read()
						if (done) {
							this.messages.forEach(item => { 
								if(item.id == id){
									console.log(item)
									item.load = false
								}
							})
							this.send_disabled = false
							this.resetUseCount()
							break
						}
						const text = new TextDecoder().decode(value)
						this.messages.forEach(item => { 
							if(item.id == id){
								console.log(item)
								item.load = true
								item.message = item.message + text
							}
						})
						this.toBottom()
						console.log(text)
					}
					if (response.status === 500) {
						this.messages.forEach(item => { 
							if(item.id == id){
								console.log(item)
								item.load = false
								item.message = '网络崩溃'
							}
						})
					}
					this.send_disabled = false
				} catch (error) {
					console.log(error)
					this.messages.forEach(item => { 
						if(item.id == id){
							console.log(item)
							item.load = false
							item.message = '网络崩溃'
						}
					})
					this.send_disabled = false
				}
			},
			sendMessage(){
				this.send_disabled = true
				this.message_value = this.message_value.replace(/\n|\r\n/g,"<br>");
				let question_time = new Date().toLocaleString().replaceAll('/','-')
				this.messages.push({
					'id':'user'+new Date().getTime(),
					'type':'0',
					'message':this.message_value,
					'time':question_time,
					'load':false
				})
				let id = 'ai'+new Date().getTime()
				this.messages.push({
					'id':id,
					'type':'1',
					'message':'',
					'time':question_time,
					'load':true
				})
				this.toBottom()
				this.chatStream(this.message_value,this.room_id,id,question_time,this.room_type)
				this.message_value = ''
			},
			resetMessage(room_id){
				console.log(room_id)
				if(!room_id) return
				this.room_id = room_id
				this.messages = [];
				let messageList = []
				messageList.push(
					{
						'type':'1',
						'message':'你好，有什么可以帮您的。',
						'time':new Date().toLocaleString(),
						'load':false
					}
				)
				axios.get(
					'http://ai.tool.winndoo.cn/api/messages?room_id='+this.room_id
				).then(response => {
					if(response.data.errno == 0){
						response.data.re.forEach(item => { 
							messageList.push(
								{
									'type':item.role == 'user' ? '0' : '1',
									'message':item.content,
									'time':item.date,
									'load':false
								}
							)
						})
						this.messages = messageList
						this.toBottom()
					}
				}).catch(error => {
				})
			},
			toBottom(){
				this.$nextTick(() => {
					let box = this.$el.querySelector(".top-1")
					box.scrollTop = box.scrollHeight
				});
			},
			checkLogin(){
				let timer = setInterval(() => {
					let user_info = localStorage.getItem('user_info')
					user_info = JSON.parse(user_info)
					if(!user_info || !user_info.startTime){
						clearInterval(timer)
						localStorage.removeItem("user_info");
						this.goLogin()
						return
					}
					let date = new Date().getTime();
					if (date - user_info.startTime > user_info.expire) {
						console.log('超时')
						localStorage.removeItem("user_info");
						clearInterval(timer)
						this.goLogin()
						return
					}else{
						console.log(user_info)
					}
				},10000)
			},
			goLogin(){
				if(this.$route.name == 'Sign-In'){
					return
				}
				var _that = this
				this.$confirm({
					title: 'Winndoo GPT',
					cancelText:'联系客服',
					okText:'去登陆',
					keyboard:false,
					content: '未登陆或者会话已经失效,请重新登陆',
					onOk() {
						return _that.$router.push({ name:'Sign-In' })
					},
					onCancel() {
						return _that.$router.push({ name:'Sign-In' })
					},
				});
			}
		},
		mounted() {
			this.room_id = this.$route.query.room_id
			this.room_type = this.$route.query.room_type
			console.log(this.room_type)
			let user_info = localStorage.getItem('user_info')
			user_info = JSON.parse(user_info)
			if(!user_info){
				this.goLogin()
			}else{
				if(!user_info.startTime){
					localStorage.removeItem("user_info");
					this.goLogin()
				}else{
					this.resetMessage(this.room_id)
					this.checkLogin()
				}
			}
		},
		watch: {
		}
	})

</script>

<style lang="scss">
</style>